export const MetaPageData = [
    // {
    //     url: '/',
    //     title:'Buy Online Air Tickets &amp; Flight Deals On Cheap Rates - Faremakers',
    //     description:'Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.',
    //     keywords:'online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.'
    // },
    // {
    //     url: '/searchflightresult',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },
    // {
    //     url: '/flightbooking',
    //     title:'Search Cheap Flight Tickets – Faremakers',
    //     description:'Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.',
    //     keywords:'Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.'
    // },

    {
        "url": "/",
        "title": "Buy Online Air Tickets Flight Deals On Cheap Rates - Faremakers",
        "description": "Faremakers is one of the best traveling Platform. Where you can buy online airline tickets and make your traveling plan at home. We provide services 24/7.",
        "keywords": "online flight booking, book cheap flights, online booking international flights, online air tickets, international domestic flights, affordable airline tickets, online air tickets, hajj umrah packages.",
        "snacks": []
      },
      {
        "url": "/searchflightresult",
        "title": "Search Cheap Flight Tickets – Faremakers",
        "description": "Search and book cheap flight tickets at faremakers.com and get amazing discounted deals.",
        "keywords": "Cheap flights, cheap flights booking, search cheap flights, online flights booking, airlines tickets rates.",
        "snacks": []
      },
      {
        "url": "/flightbooking",
        "title": "Book Flight",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/bookingpayment",
        "title": "Booking Methodes",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/contact-us",
        "title": "Contact Us for Air Ticket Booking in Pakistan. View our offices.",
        "description": "Contact Us for doing any kind of flight ticket reservation in Pakistan. Our team is present 24/7 to support you. You can contact any of our offices.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/customer-support",
        "title": "Customer Support",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-of-service",
        "title": "Terms of Service",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/refund-policy",
        "title": "Refund Policy",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/terms-and-conditions",
        "title": "Terms and Condition",
        "description": "Need Help in Flight Tickets or Having Any Problem. Contact Us. Get Answers of Common Questions Related to Flight Booking.",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/banks",
        "title": "Bank Accounts",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/blogs/pia-is-increasing-its-flights-to-saudi-arabia",
        "title": "pia is increasing its flights to saudi arabia",
        "description": "abc",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-london",
        "title": "Cheap Flights From Lahore To London",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      // --------------Domestic Pages Title -------------------
      {
        "url": "/flights/lahore-to-karachi",
        "title": "Cheap Flights From Lahore To Karachi",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-islamabad",
        "title": "Cheap Flights From Lahore To Islambad",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-peshawar",
        "title": "Cheap Flights From Lahore To Peshawar",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-faisalabad",
        "title": "Cheap Flights From Lahore To Faisalabad",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-quetta",
        "title": "Cheap Flights From Lahore To Quetta",
        "description": "",
        "keywords": "",
        "snacks": []
      },

      // --------------International Pages Title -------------------
        {
        "url": "/flights/lahore-to-london",
        "title": "Cheap Flights from Lahore to London",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-dubai",
        "title": "Cheap Flights from Lahore to Dubai",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
        "url": "/flights/lahore-to-toronto",
        "title": "Cheap Flights from Lahore to Toronto",
        "description": "",
        "keywords": "",
        "snacks": []
      },
      {
          "url": "/flights/lahore-to-newyork",
          "title": "Cheap Flights from Lahore (LHE) to New York (NYC)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-boston",
          "title": "Cheap Flight Deals from Lahore to Boston (LHE-BOS)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-perth",
          "title": "Cheap Flights from Lahore to Perth (LHE-PER)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-manchester",
          "title": "Find Cheap Flights from Lahore to Manchester",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-melbourne",
          "title": "Cheap Flights from Lahore (LHE) to Melbourne (MEL)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-atlanta",
          "title": "Cheap Flight Deals from Lahore to Atlanta (LHE-ATL)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-doha",
          "title": "Cheap Flight Deals from Lahore to Doha (LHE-DOH)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-sydney",
          "title": "Cheap Flights from Lahore (LHE) to Sydney (SYD)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-london",
          "title": "Cheap Flights From Peshawar to London (PEW-LON)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-dubai",
          "title": "Cheap Flight Deals from Peshawar to Dubai (PEW-DXB)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-toronto",
          "title": "Cheap Flight Deals from Peshawar to Toronto (PEW-YTZ)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-newyork",
          "title": "Cheap Flights from Peshawar to New York (PEW-JFK)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-boston",
          "title": "Cheap Flight Deals from peshawar-to-boston (PEW-BOS)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-perth",
          "title": "Cheap Flights from Peshawar (PEW) to Perth (PER)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-manchester",
          "title": "Flights from Peshawar (PEW) to Manchester (MAN)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-melbourne",
          "title": "Cheap Flights from Peshawar to Melbourne",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-atlanta",
          "title": "Find Cheap Flights from Peshawar to Atlanta",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-doha",
          "title": "Peshawar to Doha - Cheap Flights",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-losangeles",
          "title": "Cheap Flights from Peshawar to Los Angeles (PEW-LAX)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-jeddah",
          "title": "Cheap Flight Deals from Peshawar to Jeddah (PEW-JED)",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/peshawar-to-istanbul",
          "title": "Peshawar to Istanbul - Cheap Flights",
          "description": "",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/lahore-to-abuDhabi",
          "title": "Cheap Flights from Lahore to Abu Dhabi - Faremakers",
          "description": "Discover the best deals on cheap flights from Lahore to Abu Dhabi with Faremakers. Book your tickets now for an unforgettable travel experience!",
          "keywords": "",
          "snacks": []
        },

        // -----------------------
        {
          "url": "/flights/faisalabad-to-losAngeles",
          "title": "Affordable Flights from Faisalabad to Los Angeles",
          "description": "Find affordable flights from Faisalabad to Los Angeles with the best deals. Book now for your dream vacation!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/faisalabad-to-jeddah",
          "title": "Best Deals on Tickets from Faisalabad to Jeddah",
          "description": "Get the best deals on flights from Faisalabad to Jeddah. Book your tickets now for great savings!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/faisalabad-to-istanbul",
          "title": "Book Cheap Flights from Faisalabad to Istanbul",
          "description": "Book cheap flights from Faisalabad to Istanbul and enjoy a memorable journey at low fares!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-london",
          "title": "Cheap Airline Tickets from Karachi to London",
          "description": "Find cheap airline tickets from Karachi to London. Book now and travel in comfort at a budget!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-dubai",
          "title": "Budget Flights from Karachi to Dubai",
          "description": "Discover budget flights from Karachi to Dubai. Book today and save on your trip to the UAE!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-toronto",
          "title": "Low-Cost Tickets from Karachi to Toronto",
          "description": "Get low-cost tickets from Karachi to Toronto. Book now for a convenient and affordable journey!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-newyork",
          "title": "Discounted Flights from Karachi to New York",
          "description": "Enjoy discounted flights from Karachi to New York. Book now for an amazing trip at a lower price!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-boston",
          "title": "Special Offers on Flights from Karachi to Boston",
          "description": "Grab special offers on flights from Karachi to Boston. Book today and enjoy huge savings!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-perth",
          "title": "Cheap Airfare from Karachi to Perth",
          "description": "Find cheap airfare from Karachi to Perth. Book your tickets now for an unforgettable travel experience!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-manchester",
          "title": "Best Flight Deals from Karachi to Manchester",
          "description": "Get the best flight deals from Karachi to Manchester. Book now and travel affordably!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-melbourne",
          "title": "Low Fares from Karachi to Melbourne",
          "description": "Enjoy low fares from Karachi to Melbourne. Book now for a budget-friendly trip!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-atlanta",
          "title": "Affordable Tickets from Karachi to Atlanta",
          "description": "Book affordable tickets from Karachi to Atlanta today and enjoy great savings on your journey!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-doha",
          "title": "Cheap Flights from Karachi to Doha",
          "description": "Find cheap flights from Karachi to Doha. Book now for a cost-effective and comfortable travel experience!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-losangeles",
          "title": "Best Price Flights from Karachi to Los Angeles",
          "description": "Book the best-priced flights from Karachi to Los Angeles. Fly affordably with great offers!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-jeddah",
          "title": "Cheap Plane Tickets from Karachi to Jeddah",
          "description": "Grab cheap plane tickets from Karachi to Jeddah now and save big on your trip!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/karachi-to-istanbul",
          "title": "Discount Flights from Karachi to Istanbul",
          "description": "Get discounted flights from Karachi to Istanbul. Book today for affordable travel!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-london",
          "title": "Budget Travel from Quetta to London",
          "description": "Enjoy budget travel from Quetta to London. Book now for the best flight deals!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-dubai",
          "title": "Low-Cost Airfare from Quetta to Dubai",
          "description": "Book low-cost airfare from Quetta to Dubai. Fly affordably with the best rates available!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-toronto",
          "title": "Cheap Tickets from Quetta to Toronto",
          "description": "Get cheap tickets from Quetta to Toronto. Book now and enjoy a smooth journey at great prices!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-newyork",
          "title": "Special Airfare Deals from Quetta to New York",
          "description": "Special airfare deals from Quetta to New York. Book today and save on your trip!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-boston",
          "title": "Affordable Flights from Quetta to Boston",
          "description": "Affordable flights from Quetta to Boston. Book now for a great travel experience at low prices!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-perth",
          "title": "Discount Air Tickets from Quetta to Perth",
          "description": "Discount air tickets from Quetta to Perth. Book today and enjoy big savings on your flight!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-manchester",
          "title": "Best Flight Deals from Quetta to Manchester",
          "description": "Find the best flight deals from Quetta to Manchester. Book now and fly at a low cost!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-melbourne",
          "title": "Cheap Flights from Quetta to Melbourne",
          "description": "Cheap flights from Quetta to Melbourne. Book now for affordable and smooth travel!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-atlanta",
          "title": "Best Airfare Deals from Quetta to Atlanta",
          "description": "Get the best airfare deals from Quetta to Atlanta. Book now for low-cost flights!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-doha",
          "title": "Affordable Travel from Quetta to Doha",
          "description": "Affordable travel from Quetta to Doha. Book today for cheap tickets and a comfortable journey!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-losangeles",
          "title": "Low-Cost Flights from Quetta to Los Angeles",
          "description": "Book low-cost flights from Quetta to Los Angeles. Enjoy the best offers on your trip!",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-jeddah",
          "title": "Cheap Tickets from Quetta to Jeddah",
          "description": "Find discounted fares on flights from Quetta to Jeddah and book the best travel deals at unbeatable prices.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/quetta-to-istanbul",
          "title": "Discount Flights from Quetta to Istanbul",
          "description": "Book discounted flights from Quetta to Istanbul and enjoy exclusive airfare deals for your journey.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-london",
          "title": "Book Cheap Tickets from Islamabad to London",
          "description": "Discover affordable tickets from Islamabad to London with the best deals and offers available.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-dubai",
          "title": "Low-Cost Flights from Islamabad to Dubai",
          "description": "Book the lowest fares on flights from Islamabad to Dubai and travel for less.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-toronto",
          "title": "Cheap Travel from Islamabad to Toronto",
          "description": "Get budget-friendly travel options from Islamabad to Toronto with cheap flight tickets.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-newyork",
          "title": "Best Ticket Prices from Islamabad to New York",
          "description": "Find the best ticket prices for flights from Islamabad to New York and save on your next trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-boston",
          "title": "Special Deals on Flights from Islamabad to Boston",
          "description": "Explore special offers on flights from Islamabad to Boston and book your tickets at discounted rates.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-perth",
          "title": "Cheap Airfare from Islamabad to Perth",
          "description": "Book cheap airfare from Islamabad to Perth and enjoy unbeatable travel deals.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-manchester",
          "title": "Low-Cost Airline Tickets from Islamabad to Manchester",
          "description": "Find low-cost airline tickets from Islamabad to Manchester and travel for less.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-melbourne",
          "title": "Budget Flights from Islamabad to Melbourne",
          "description": "Book budget flights from Islamabad to Melbourne and take advantage of exclusive offers.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-atlanta",
          "title": "Discount Tickets from Islamabad to Atlanta",
          "description": "Grab discounted tickets for flights from Islamabad to Atlanta with great deals.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-doha",
          "title": "Affordable Flights from Islamabad to Doha",
          "description": "Find affordable flights from Islamabad to Doha and book at the lowest prices.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-losangeles",
          "title": "Cheap Airfare from Islamabad to Los Angeles",
          "description": "Book cheap flights from Islamabad to Los Angeles and get the best travel deals.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-jeddah",
          "title": "Low-Cost Travel from Islamabad to Jeddah",
          "description": "Book low-cost travel options from Islamabad to Jeddah with cheap tickets.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/islamabad-to-istanbul",
          "title": "Best Flight Deals from Islamabad to Istanbul",
          "description": "Find the best flight deals from Islamabad to Istanbul and book affordable tickets.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-london",
          "title": "Cheap Flights from Multan to London",
          "description": "Book cheap flights from Multan to London and enjoy the best deals on airfare.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-dubai",
          "title": "Best Airfare Deals from Multan to Dubai",
          "description": "Find the best airfare deals on flights from Multan to Dubai and book now for less.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-toronto",
          "title": "Discounted Tickets from Multan to Toronto",
          "description": "Book discounted tickets from Multan to Toronto and get the best value for your money.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-newyork",
          "title": "Affordable Travel from Multan to New York",
          "description": "Enjoy affordable travel from Multan to New York with the best ticket prices available.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-boston",
          "title": "Cheap Airline Tickets from Multan to Boston",
          "description": "Find cheap airline tickets from Multan to Boston and save on your next trip.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-perth",
          "title": "Best Price Flights from Multan to Perth",
          "description": "Book flights from Multan to Perth at the best prices with exclusive offers.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-manchester",
          "title": "Budget Flights from Multan to Manchester",
          "description": "Find budget-friendly flights from Multan to Manchester and travel for less.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-melbourne",
          "title": "Cheap Plane Tickets from Multan to Melbourne",
          "description": "Book cheap plane tickets from Multan to Melbourne with exclusive discounts.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-atlanta",
          "title": "Special Deals on Flights from Multan to Atlanta",
          "description": "Grab special deals on flights from Multan to Atlanta and enjoy affordable travel.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-doha",
          "title": "Low-Cost Airfare from Multan to Doha",
          "description": "Find low-cost airfare from Multan to Doha with the best deals available.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-losangeles",
          "title": "Discount Flights from Multan to Los Angeles",
          "description": "Book discounted flights from Multan to Los Angeles and enjoy affordable travel.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-jeddah",
          "title": "Affordable Flights from Multan to Jeddah",
          "description": "Find affordable flights from Multan to Jeddah and book at the lowest prices.",
          "keywords": "",
          "snacks": []
        },
        {
          "url": "/flights/multan-to-istanbul",
          "title": "Best Airfare from Multan to Istanbul",
          "description": "Book the best airfare from Multan to Istanbul with exclusive discounts.",
          "keywords": "",
          "snacks": []
        }
]